import { Loader } from '@googlemaps/js-api-loader'
import get from 'lodash-es/get'
import { loadGoogleMaps } from '/~/boot/google-maps'
import emitter from '/~/core/emitter'
import appConfig from '/~/config'

export class MapWorker {
  initialized = false
  authFailed = false
  visible = false
  userLocation: any = null
  center: any = null
  place: any = null
  bounds: any = null
  zoom: any = null
  autocomplete: any
  geocoder?: google.maps.Geocoder

  loader?: Loader
  geocodingLib?: google.maps.GeocodingLibrary
  placesLib?: google.maps.PlacesLibrary

  constructor() {
    this.reset()

    window.gm_authFailure = () => {
      this.authFailed = true
      emitter.emit('google-map:auth-failed')
    }
  }

  get isEnabled() {
    return !this.authFailed
  }

  get isLoaded() {
    return this.initialized && this.isEnabled
  }

  get isVisible() {
    return this.isLoaded && this.visible
  }

  get isReady() {
    return !this.isEnabled || Boolean(this.bounds || this.place || this.center)
  }

  get routeQuery() {
    if (this.visible) {
      return {
        map: true,
        center: this.center && this.center.toUrlValue(),
        zoom: this.zoom,
      }
    } else if (this.place) {
      return {
        placeId: get(this.place, 'place_id'),
      }
    }

    return {}
  }

  get boundsString() {
    return this.bounds ? this.bounds.toUrlValue() : ''
  }

  reset() {
    /*
     * Visible attribute used for the base-map component.
     * Without base-map this state can be used as a place parser.
     */
    this.visible = false
    this.center = null
    this.place = null
    this.zoom = null
    this.bounds = null
  }

  /*
   * We should load Google maps when it's needed,
   * because the map state is created with its own state
   */
  async init() {
    this.loader = loadGoogleMaps()
    this.geocodingLib = await this.loader.importLibrary('geocoding')
    this.placesLib = await this.loader.importLibrary('places')

    if (this.placesLib) {
      this.autocomplete = new this.placesLib.AutocompleteService()
    }
    if (this.geocodingLib) {
      this.geocoder = new this.geocodingLib.Geocoder()
    }
    this.initialized = true
  }

  async sync(routeQuery: any = {}) {
    if (!this.initialized) {
      await this.init()
    }

    const { placeId, zoom, center, map } = routeQuery

    this.toggleVisibility(Boolean(map))

    if (zoom) {
      this.zoom = Number(zoom)
    }

    if (placeId) {
      await this.updateByPlaceId(placeId)
    } else if (center) {
      this.updateByCenter(center)
    } else {
      await this.updateByUserLocation()
    }
  }

  async updateByPlaceId(placeId: string) {
    if (placeId !== this.routeQuery.placeId) {
      await this.setPlace({ placeId })
    }
  }

  updateByCenter(center: any) {
    const { LatLng } = window.google.maps

    if (center !== this.routeQuery.center) {
      const [lat, lng] = center.split(',')

      this.setCenter(new LatLng(lat, lng))
    }
  }

  getGeoLocation() {
    return new Promise((resolve, reject) => {
      if (this.userLocation) {
        resolve(this.userLocation)
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.userLocation = position || null
          resolve(position)
        },
        (error) => {
          this.userLocation = null
          reject(error)
        },
        /*
         * Options
         */
        {
          timeout: 10000,
        }
      )
    })
  }

  async updateByUserLocation({ initDefault = true, radius }: any = {}) {
    if (!this.initialized) {
      await this.init()
    }

    const applyLocation = (location: any) => {
      if (radius) {
        return this.setUserRadius(location, radius)
      }

      return this.setUserLocation(location)
    }

    if (this.userLocation) {
      applyLocation(this.userLocation)
    } else {
      return this.getGeoLocation()
        .then(applyLocation)
        .catch(() => {
          if (initDefault) {
            this.setPlace({
              placeId: appConfig.mapDefaultPlaceId,
            })
          }
        })
    }
  }

  async setPlace(payload: any, hasMap = true) {
    const res = await new Promise<void>((resolve) => {
      this.geocoder?.geocode(
        payload,
        (
          result: google.maps.GeocoderResult[] | null,
          status: google.maps.GeocoderStatus
        ) => {
          if (this.placesLib && status !== google.maps.GeocoderStatus.OK) {
            return resolve()
          }

          let place: google.maps.GeocoderResult | undefined

          if (result?.length === 1) {
            place = result.shift()
          } else {
            place = result?.find((loc) => {
              return loc.types.indexOf('administrative_area_level_2') >= 0
            })

            if (!place) {
              place = result?.find((loc) => {
                return loc.types.indexOf('locality') >= 0
              })
            }
          }
          /*
           * If map is visible bounds will be set by base-map component
           */
          if (!this.visible && hasMap && place) {
            this.setBounds(place.geometry.bounds)
          }

          this.place = place
          resolve()
        }
      )
    })

    return res
  }

  setUserRadius(position: any, radius: any) {
    const { LatLng, Circle } = window.google.maps
    const { latitude, longitude } = get(position, 'coords', {})

    const circle = new Circle({
      radius,
      center: new LatLng(latitude, longitude),
    })

    this.setBounds(circle.getBounds())
  }

  async setUserLocation(position: any) {
    const { LatLng } = window.google.maps
    const { latitude, longitude } = get(position, 'coords', {})

    await this.setPlace({
      location: new LatLng(latitude, longitude),
    })
  }

  setBounds(bounds: any) {
    if (!this.bounds || this.bounds.toUrlValue() !== bounds.toUrlValue()) {
      this.bounds = bounds
    }
  }

  setCenter(center: any) {
    this.center = center
  }

  setZoom(zoom: any) {
    this.zoom = zoom
  }

  toggleVisibility(value: boolean | undefined = undefined) {
    this.visible = typeof value === 'boolean' ? value : !this.visible
  }

  /*
   * Experimental usage
   */
  getPlacePredictions(queryString: string, types: any = undefined) {
    return new Promise((resolve) => {
      this.autocomplete.getPlacePredictions(
        {
          input: queryString,
          types: [types || '(regions)'],
          componentRestrictions: {
            country: ['au'],
          },
        },
        (locations: any) => {
          resolve(locations || [])
        }
      )
    })
  }
}
